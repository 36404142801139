




import Vue from 'vue';
import ProfileCard from '@/components/Settings/Card.vue';

export default Vue.extend({
  name: 'Support',
  components: {
    ProfileCard,
  },
  data: () => ({
    content: {
      twoLine: true,
      items: [{
        icon: 'mdi-email-outline',
        title: 'profile.views.support.email',
        subtitle: 'support@cometum.com',
        externalPath: 'mailto:support@cometum.com',
      },
      {
        icon: 'mdi-phone-outline',
        title: 'profile.views.support.phone',
        subtitle: '+49 1511 802 65 40',
        externalPath: 'tel:+49 1511 802 65 40',
      },
      {
        icon: 'mdi-whatsapp',
        title: 'profile.views.support.whatsapp',
        subtitle: '+49 1511 802 65 40',
        externalPath: 'https://wa.me/4915118026540',
      },
      {
        icon: 'mdi-telegram',
        title: 'profile.views.support.telegram',
        subtitle: 'https://t.me/cometum',
        externalPath: 'https://t.me/cometum',
      }],
    },
  }),
});
